
              @import "@/assets/css/variables.scss";
            






















































































































































































































































































































// @import '@/assets/css/pages/home/chart.scss';
#echarts {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include screen-mobile {
    flex-direction: column;
  }
  .echarts_card {
    width: 50%;
    margin-top: 0;
    margin-bottom: 16px;
    @include rtl-sass-prop(margin-right, margin-left, 16px);
    &:last-child {
      @include rtl-sass-prop(margin-right, margin-left, 0px);
    }
    @include screen-mobile {
      width: 100%;
      @include rtl-sass-prop(margin-right, margin-left, 0px);
    }
    .echarts_title {
      font-size: 16px;
      line-height: 24px;
      font-weight: 700;
      color: $text-primary;
      padding-bottom: 16px;
    }
    .echarts_content {
      width: 100%;
      height: 350px;
      min-width: 330px;
    }
  }
}
