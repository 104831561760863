
              @import "@/assets/css/variables.scss";
            









.hoverShow {
  position: absolute;
  top: 0;
  width: 100%;
  border-radius: 8px;
  background-color: $gray;
  box-shadow: 0px 0px 14.72px 1.28px rgba(11, 16, 122, 0.09);
  z-index: 2042;
  font-size: 15px;
  color: $white;
  padding: 10px 20px;
  line-height: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  display: none !important;

  &:before {
    content: '';
    width: 0;
    height: 0;
    border-width: 0 4px 6px;
    border-style: solid;
    border-color: transparent transparent $gray;
    position: absolute;
    left: 40%;
    top: -6px;
  }
}

@media (max-width: 414px) {
  .hoverShow {
    font-size: 14px;
  }
}
