
              @import "@/assets/css/variables.scss";
            











































































































































































































































// @import '@/assets/css/pages/home/index.scss';
// @import '@/views/home/common.scss';
.piece_list {
  // width: 100%;
  margin-top: 16px;
  .piece_item {
    width: 100%;
    padding: 24px;
    background-color: $white;
    border-radius: 8px;
    margin-bottom: 16px;
    cursor: pointer;
    border: 1px solid transparent;
    .item_top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 8px;
      .top_left {
        display: flex;
        align-items: center;
        color: $text-secondary;
        font-size: 14px;

        img {
          width: 24px;
          @include rtl-sass-prop(margin-right, margin-left, 8px);
        }
      }
    }
    .item_bottom {
      color: $text-primary;
      font-weight: 700;
      font-size: 20px; 
      text-align: center;
      .bottom_currency {
        @include rtl-sass-prop(padding-right, padding-left, 5px);
      }
    }

    &:hover {
      box-shadow: 0px 8px 20px rgba(14, 18, 54, 0.1);
    }
  }
  .active {
    border: 1px solid $primary;
  }
}
.data_table {
  width: 100%;
  display: flex;
  @include screen-mobile {
    flex-direction: column;
  }
  .table_title {
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    color: $text-primary;
    padding-bottom: 16px;
  }
  .table_box {
    width: 50%;
    margin-top: 0;
    margin-bottom: 16px;
    @include rtl-sass-prop(margin-right, margin-left, 16px);
    &:last-child {
      @include rtl-sass-prop(margin-right, margin-left, 0px);
    }
    @include screen-mobile {
      width: 100%;
      @include rtl-sass-prop(margin-right, margin-left, 0px);
    }
  }
}
