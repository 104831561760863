
              @import "@/assets/css/variables.scss";
            






























































































































// @import '@/assets/css/components/rebate.scss';
// @import '@/assets/css/components/vDialog.scss';
#RebateCard {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include screen-mobile {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  .card_item {
    width: 50%;
    padding: 24px;
    border-radius: 8px;
    color: $white;
    margin-bottom: 16px;
    @include screen-mobile {
      width: 100%;
    }
    .card_title {
      margin-bottom: 8px;
      display: flex;
      align-items: center;
       .title_text {
        font-size: 16px;
        line-height: 24px;
        @include rtl-sass-prop(padding-right, padding-left, 8px);
      }
    }
    .card_bottom {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      @include screen-mobile {
        flex-direction: column;
      }
      .bottom_con {
        display: flex;
        align-items: center;
        @include screen-mobile {
          width: 100%;
        }
        .currency_symbol,
        .card_num {
          font-size: 32px;
          line-height: 48px;
          font-weight: 700;
        }
        .currency_symbol {
          @include rtl-sass-prop(padding-right, padding-left, 8px);
        }

        .card_currency {
          display: inline-block;
          padding: 4px 8px;
          border-radius: 10px;
          background-color: rgba($white, 0.6);
          font-size: 12px;
          font-weight: normal;
          color: $text-primary; 
          line-height: 12px;
          @include rtl-sass-prop(margin-left, margin-right, 8px);
        }
      }
    }

    &.left_card {
      @include rtl-sass-prop(margin-right, margin-left, 16px);
      background: linear-gradient(92.97deg, #04DACE 3.68%, #2482F7 98.91%), #FFFFFF;
      @include screen-mobile {
        @include rtl-sass-prop(margin-right, margin-left, 0px);
      }
    }
    &.right_card {
      background: linear-gradient(92.97deg, #2482F7 3.68%, #767BFF 98.91%), #FFFFFF;
    }
  }
}
